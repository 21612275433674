import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhModalComponent} from "./bh-modal.component";
import {AppTranslocoRootModule} from "../../../app.transloco.module";
import {BhInviteModule} from "../bh-invite/bh-invite.module";
import {BhLanguageSelectModule} from "../bh-language-select/bh-language-select.module";
import {BhChangeMultipasportModule} from "../bh-change-multipasport/bh-change-multipasport.module";
import {BhGetPremiumModule} from "../bh-get-premium/bh-get-premium.module";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {BhMinicartModule} from "../bh-minicart/bh-minicart.module";
import {BhCreateMultipasportModule} from "../bh-create-multipasport/bh-create-multipasport.module";
import {BhLoginModule} from "../bh-login/bh-login.module";
import {RouterLink} from "@angular/router";
import {BhLeaveReviewModule} from "../bh-leave-review/bh-leave-review.module";
import {BhVideoPlayerModule} from "../bh-videoplayer/bh-videoplayer.module";


@NgModule({
  declarations: [BhModalComponent],
  exports: [BhModalComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'modal', alias: 'modal'  } }],
    imports: [
        CommonModule,
        AppTranslocoRootModule,
        BhInviteModule,
        BhLanguageSelectModule,
        BhChangeMultipasportModule,
        BhGetPremiumModule,
        NgOptimizedImage,
        BhMinicartModule,
        BhCreateMultipasportModule,
        BhLoginModule,
        RouterLink,
        BhLeaveReviewModule,
        BhVideoPlayerModule
    ]
})
export class BhModalModule { }

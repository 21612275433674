<div class="bh-login" [class.bh-login-horizontal]="horizontal">
  <div class="bh-login-form min-w-[19rem]">
    <div class="bh-login-title" *ngIf="!checkout">Log in</div>
    <div class="bh-form" >
        <div class="bh-form-text bh-form-text-xs">
            <span *ngIf="!interface._text">&nbsp;<!--Log in to get access to learning system.--></span>
            <span *ngIf="interface._text">&nbsp;<!--{{interface._text}}--></span>
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-lightgray mt-2 mb-4">
            <span>If you do not have an account,</span>
            <span class="bh-form-link" (click)="actionOpenCreateMultipasport()">click here to Register</span>
        </div>
      <div class="bh-form-input-group">
        <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('email')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
          </svg>
          <input name="email_bh" type="email"  placeholder="{{'placeholders.email' | transloco}}" [readonly]="interface.submit" [value]="interface.card.email" (keyup)="actionEnterEmail($event)" (paste)="actionPasteEmail($event)" (focusout)="actionGrantEmail()">
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('email')">No account found with this email address</ng-container>&nbsp;</div>
      </div>
      <div class="bh-form-input-group">
        <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('password')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33" />
            </svg>
            <input name="password" type="password"  placeholder="{{'placeholders.password' | transloco}}" [value]="interface.card.password" (keyup)="actionEnterPassword($event)" (paste)="actionPastePassword($event)" (focusout)="actionGrantPassword()" #password [readonly]="interface.submit">
          <svg *ngIf="!interface._cache.show_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="{{'tooltips.show_password' | transloco}}" (click)="actionToggleShowPassword()">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <svg *ngIf="interface._cache.show_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="{{'tooltips.hide_password' | transloco}}" (click)="actionToggleShowPassword()">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
          </svg>
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('password')">{{'errors.login.password' | transloco}}</ng-container>&nbsp;</div>
      </div>

      <button class="bh-btn bh-btn-sap bh-btn-lg !mt-0" [disabled]="!actionGrant() || interface.submit" (click)="actionLogin()">
        <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
          </svg>
          <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span *ngIf="!interface.submit" >Login your account</span>
        <span *ngIf="interface.submit" >{{'global.check' | transloco}}</span>
      </button>
        <div class="bh-form-text bh-form-text-sm mt-3">
            <span class="bh-form-link" (click)="actionOpenForgotAccess()">{{'buttons.forgot' | transloco}}</span>
        </div>
      <div class="bh-form-divider-extend mt-4">
        <div class="bh-form-divider-extend-line" aria-hidden="true">
          <span></span>
        </div>
        <div class="bh-form-divider-extend-text ">
          <span>or Social</span>
        </div>
        <div class="bh-form-divider-extend-line" aria-hidden="true">
          <span></span>
        </div>
      </div>
        <div class="bh-form-button-group">
            <button class="bh-btn bh-btn-white-outline w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="none" class="w-6 h-6">
                    <path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"/>
                </svg>
                <span>Facebook</span>
            </button>
            <button class="bh-btn bh-btn-white-outline w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="none" class="w-6 h-6">
                    <path d="M17.5 14a5.51 5.51 0 0 1-4.5 3.93 6.15 6.15 0 0 1-7-5.45A6 6 0 0 1 12 6a6.12 6.12 0 0 1 2.27.44.5.5 0 0 0 .64-.21l1.44-2.65a.52.52 0 0 0-.23-.7A10 10 0 0 0 2 12.29 10.12 10.12 0 0 0 11.57 22 10 10 0 0 0 22 12.52v-2a.51.51 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h5"></path>
                </svg>
                <span>Google</span>
            </button>
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-lightgray mt-4 !flow-root !hidden">
            <span>By clicking the login your account button, you agree to our</span>
            <span class="bh-form-link" routerLink="/terms/"> Terms of Use</span>
            <span> and </span>
            <span class="bh-form-link" routerLink="/privacy-policy/">Privacy Policy</span>
            <span>. You will receive occasional emails from us.</span>
        </div>
    </div>
  </div>
</div>

import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject('serverUrl') protected serverUrl: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
   // console.log(this.serverUrl, req.url);

    let updatedUrl = req.url;
    if (!req.url.startsWith('https://argoprep.online')) {
      updatedUrl = `https://argoprep.online${req.url}`;
    }

    const serverReq = !this.serverUrl
        ? req.clone({ url: updatedUrl })
        : req.clone({ url: `${this.serverUrl}${updatedUrl}` });

    return next.handle(serverReq);
  }
}

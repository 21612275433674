<div class="bh-videoplayer" #videoContainer>
  <div class="bh-videoplayer-overlay" [class.bh-videoplayer-overlay-hidden]="interface.controls.play" (click)="actionPlayerPlay()">
    <svg (click)="actionPlayerPlay()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="0.5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  </div>
  <div class="bh-videoplayer-toolbar">
    <div class="bh-videoplayer-toolbar-control">
      <svg *ngIf="!interface.controls.play" (click)="actionPlayerPlay()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M20.3131 10.3077C21.5586 11.0922 21.5586 12.9078 20.3131 13.6923L9.56588 20.4613C8.23404 21.3001 6.5 20.343 6.5 18.769V5.23101C6.5 3.65702 8.23404 2.69986 9.56588 3.5387L20.3131 10.3077Z" fill="currentColor"/>
      </svg>
      <svg *ngIf="interface.controls.play" (click)="actionPlayerPause()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M9 5H7C6.44772 5 6 5.44772 6 6V18C6 18.5523 6.44772 19 7 19H9C9.55228 19 10 18.5523 10 18V6C10 5.44772 9.55228 5 9 5Z" fill="currentColor"/>
        <path d="M17 5H15C14.4477 5 14 5.44772 14 6V18C14 18.5523 14.4477 19 15 19H17C17.5523 19 18 18.5523 18 18V6C18 5.44772 17.5523 5 17 5Z" fill="currentColor"/>
      </svg>
    </div>
    <div class="bh-videoplayer-toolbar-progress">
      <progress [value]="interface.controls.time.current" [max]="interface.controls.time.total" (mouseup)="actionPlayerProgressChange($event)" #progress></progress>
    </div>
    <div class="bh-videoplayer-toolbar-time">{{interface.controls.time.current | timer}}</div>
    <div class="bh-videoplayer-toolbar-control" >

      <span class="bh-videoplayer-toolbar-control-play-speed-icon" *ngIf="interface.controls.playbackRate == 0.5">0.5x</span>
      <span class="bh-videoplayer-toolbar-control-play-speed-icon" *ngIf="interface.controls.playbackRate == 0.8">0.8x</span>
      <span class="bh-videoplayer-toolbar-control-play-speed-icon" *ngIf="interface.controls.playbackRate == 1">1x</span>
      <span class="bh-videoplayer-toolbar-control-play-speed-icon" *ngIf="interface.controls.playbackRate == 1.5">1.5x</span>
      <span class="bh-videoplayer-toolbar-control-play-speed-icon" *ngIf="interface.controls.playbackRate == 2">2x</span>
      <div class="bh-videoplayer-toolbar-control-play-speed-selector">
        <div class="bh-videoplayer-toolbar-control-play-speed-item" (click)="actionPlayerToggleSpeed(0.5)"><span>0.5x</span></div>
        <div class="bh-videoplayer-toolbar-control-play-speed-item" (click)="actionPlayerToggleSpeed(0.8)"><span>0.8x</span></div>
        <div class="bh-videoplayer-toolbar-control-play-speed-item" (click)="actionPlayerToggleSpeed(1)"><span>1x</span></div>
        <div class="bh-videoplayer-toolbar-control-play-speed-item" (click)="actionPlayerToggleSpeed(1.5)"><span>1.5x</span></div>
        <div class="bh-videoplayer-toolbar-control-play-speed-item" (click)="actionPlayerToggleSpeed(2)"><span>2x</span></div>
      </div>
    </div>
    <div class="bh-videoplayer-toolbar-control">
      <svg *ngIf="!interface.controls.muted" (click)="actionPlayerToggleVolume()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
      </svg>
      <svg *ngIf="interface.controls.muted" (click)="actionPlayerToggleVolume()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
      </svg>
      <div class="bh-videoplayer-toolbar-sound-selector">
        <progress max="100" [value]="interface.controls.volume" (mouseup)="actionPlayerVolumeChange($event)" #volume></progress>
      </div>
    </div>
    <div class="bh-videoplayer-toolbar-control">
      <svg *ngIf="interface.controls.fullscreen" (click)="actionPlayerFullScreen()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M9 5V9M9 9H5M9 9L4 4M15 5V9M15 9H19M15 9L20 4M9 19V15M9 15H5M9 15L4 20M15 15L20 20M15 15V19M15 15H19"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg *ngIf="!interface.controls.fullscreen" (click)="actionPlayerFullScreen()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
      </svg>
    </div>

  </div>
  <video id="videoplayer"
         preload="metadata"
         [poster]="interface.thumbnail"
         [src]="interface.src"
         [muted]="interface.controls.muted"
         (ended)="actionPlayerStop()"
         (timeupdate)="actionPlayerProgressTimeupdate()"
         (click)="actionPlayerPause()"
         #videoPlayer></video>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnderscorePipe} from "./_pipes/underscore.pipe";
import {StripHtmlPipe} from "./_pipes/striphtml.pipe";
import {TimeFullPipe} from "./_pipes/time-full.pipe";
import {SeriesPipe} from "./_pipes/series.pipe";
import {ResultViewerPipe} from "./_pipes/result-viewer.pipe";
import {RandomPipe} from "./_pipes/random.pipe";
import {SafePipe} from "./_pipes/safe.pipe";
import {SortPipe} from "./_pipes/sort.pipe";
import {DiffPipe} from "./_pipes/diff.pipe";
import {MathPipe} from "./_pipes/math.pipe";
import {TimerPipe} from "./_pipes/time.pipe";
import {RandomOnePipe} from "./_pipes/random_one.pipe";
import {RandomNumberPipe} from "./_pipes/random_number.pipe";
import {FilterLengthPipe} from "./_pipes/filter_type_length.pipe";
import {AbcPipe} from "./_pipes/abc.pipe";
import {LetterPipe} from "./_pipes/letter.pipe";
import {RomanNumberPipe} from "./_pipes/roman_number.pipe";
import {LetterDashPipe} from "./_pipes/letter_dash.pipe";
import {LetterShufflePipe} from "./_pipes/letter_shuffle.pipe";
import {WordShufflePipe} from "./_pipes/word_shuffle.pipe";
import {KatexPipe} from "./_pipes/katex.pipe";
import {TypewriterPipe} from "./_pipes/typewriter.pipe";
import {HighlightWordsPipe} from "./_pipes/highlightWords.pipe";
import {GetPracticeSlugPipe} from "./_pipes/practice-slug-by-id.pipe";
import {GradeSortPipe} from "./_pipes/grade-sort.pipe";


@NgModule({
  declarations: [
    UnderscorePipe,
    DiffPipe,
    SafePipe,
    SortPipe,
    MathPipe,
    StripHtmlPipe,
    TimerPipe,
    RandomPipe,
    SeriesPipe,
    ResultViewerPipe,
    TimeFullPipe,
    RandomOnePipe,
    RandomNumberPipe,
    FilterLengthPipe,
    AbcPipe,
    LetterPipe,
    RomanNumberPipe,
    LetterDashPipe,
    LetterShufflePipe,
    WordShufflePipe,
    KatexPipe,
    TypewriterPipe,
    HighlightWordsPipe,
    GetPracticeSlugPipe,
    GradeSortPipe
  ],
  exports: [
    UnderscorePipe,
    DiffPipe,
    SafePipe,
    SortPipe,
    MathPipe,
    StripHtmlPipe,
    TimerPipe,
    RandomPipe,
    SeriesPipe,
    ResultViewerPipe,
    TimeFullPipe,
    RandomOnePipe,
    RandomNumberPipe,
    FilterLengthPipe,
    AbcPipe,
    LetterPipe,
    RomanNumberPipe,
    LetterDashPipe,
    LetterShufflePipe,
    WordShufflePipe,
    KatexPipe,
    TypewriterPipe,
    HighlightWordsPipe,
    GetPracticeSlugPipe,
    GradeSortPipe
  ],
  imports: [CommonModule],
})
export class AppPipesModule {}

<div class="bh-get-premium" *ngIf="interface.plan">
  <div class="bh-get-premium-subscription">
      <div class="bh-subs-form-plan-period">
          <p *ngIf="interface.plan.period == 'month'">Monthly Subscription</p>
          <p *ngIf="interface.plan.period == 'halfyear'">6 months Subscription</p>
          <p *ngIf="interface.plan.period == 'year'">1 Year Subscription</p>
          <p *ngIf="interface.plan.period == 'lifetime'">Lifetime Subscription</p>
      </div>
      <div class="bh-subs-form-personal">
          <p>You</p>
          <small>&</small>
          <span *ngIf="interface.plan.children == 1"><b>{{interface.plan.children}}</b> child</span>
          <span *ngIf="interface.plan.children != 1"><b>{{interface.plan.children}}</b> children</span>
      </div>
      <div class="bh-subs-form-plan">
          <div class="bh-plan-cost" [class.bh-plan-cost-extra]="interface.plan.subject == 'math_ela_science'">
              <small>Math & ELA Subjects</small>
              <div>
                  <p>{{interface.plan.cost.per_month | currency: 'USD':'$'}}</p>
                  <span>/</span>
                  <span>month</span>
              </div>
              <ng-container *ngIf="interface.plan.subject == 'math_ela_science'">
                  <small>Science Subject</small>
                  <div>
                      <p>{{interface.plan.cost.science_per_month | currency: 'USD':'$'}}</p>
                      <span>/</span>
                      <span>month</span>
                  </div>
              </ng-container>
          </div>
    </div>
  </div>
  <div class="bh-form" *ngIf="interface.subscribes">
    <ngx-stripe-card-group [elementsOptions]="interface.payment.stripe.elementsOptions">
      <div class="bh-form-input-group">
        <div class="bh-form-text bh-form-text-xs">{{'components.get-premium.form.display_name' | transloco}}</div>
        <div class="bh-form-input" [class.bh-form-input-readonly]="true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <input name="display_name" type="text" role="spinbutton" [readonly]="true" [value]="interface.user.display_name">
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error">&nbsp;</div>
      </div>
      <div class="bh-form-input-group">
        <div class="bh-form-text bh-form-text-xs">{{'components.get-premium.form.card_number' | transloco}}</div>
        <div class="bh-form-input" [class.bh-form-input-error]="interface._cache.error_fields.includes('number')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
          </svg>
          <ngx-stripe-card-number [options]="interface.payment.stripe.cardOptions" (change)="actionCardNumberChange($event)"></ngx-stripe-card-number>
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error">{{interface._cache.error_messages.number}}&nbsp;</div>
      </div>
      <div class="flex flex-row gap-6 w-full">
        <div class="bh-form-input-group w-full">
          <div class="bh-form-text bh-form-text-xs">{{'components.get-premium.form.card_expiry' | transloco}}</div>
          <div class="bh-form-input" [class.bh-form-input-error]="interface._cache.error_fields.includes('expire')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <ngx-stripe-card-expiry [options]="interface.payment.stripe.cardOptions" (change)="actionCardExpireChange($event)"></ngx-stripe-card-expiry>
          </div>
          <div class="bh-form-text bh-form-text-xs bh-form-text-error">{{interface._cache.error_messages.expire}}&nbsp;</div>
        </div>
        <div class="bh-form-input-group w-full">
          <div class="bh-form-text bh-form-text-xs">{{'components.get-premium.form.card_cvc' | transloco}}</div>
          <div class="bh-form-input" [class.bh-form-input-error]="interface._cache.error_fields.includes('cvc')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
            </svg>
            <ngx-stripe-card-cvc [options]="interface.payment.stripe.cardOptions" (change)="actionCardCvcChange($event)"></ngx-stripe-card-cvc>
          </div>
          <div class="bh-form-text bh-form-text-xs bh-form-text-error">{{interface._cache.error_messages.cvc}}&nbsp;</div>
        </div>
      </div>
      <button class="bh-btn bh-btn-sap mt-4 bh-btn-full" [disabled]="checkPaymentFields() || interface.submit" (click)="actionConfirmPayment()">
        <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span *ngIf="!interface.submit" >{{'components.get-premium.buttons.confirm_payment' | transloco}}</span>
        <span *ngIf="interface.submit" >{{'global.check' | transloco}}</span>

      </button>
    </ngx-stripe-card-group>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BhVideoPlayerComponent} from "./bh-videoplayer.component";
import {AppPipesModule} from "../../../app.pipes.module";




@NgModule({
  declarations: [BhVideoPlayerComponent],
  exports: [BhVideoPlayerComponent],
  imports: [
    CommonModule,
    AppPipesModule
  ]
})
export class BhVideoPlayerModule { }

<div class="bh-tip bh-tips-confetti-animated" [class.bh-tip-heading]="interface.options.type == 'points' || interface.options.type == 'done'" *ngIf="interface?.show" (click)="actionCloseTips($event)">
  <div class="bh-container">
      <div *ngIf="interface.options.type == 'points'" class="bh-tip-points">
          <small>+</small>
          <span>{{ interface.options.count }}</span>
          <small>points</small>
      </div>
      <div *ngIf="interface.options.type == 'strike'" class="bh-tip-strike">
          <p>You've just earned <b>10</b> extra points!</p>
          <img ngSrc="./assets/images/backgrounds/bg-strike.png" alt="error" height="307" width="450"/>
      </div>
      <div *ngIf="interface.options.type == 'award'" class="bh-tip-award">
          <p>Your win award!</p>
          <img src="{{interface.options.image}}" alt="error" height="307" width="250"/>
      </div>
      <div *ngIf="interface.options.type == 'done'" class="bh-tip-done">
          <span>⭐ ⭐ ⭐ DONE ⭐ ⭐ ⭐</span>
      </div>
  </div>
</div>


import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhGetPremiumComponent} from "./bh-get-premium.component";
import {NgxStripeModule} from "ngx-stripe";
import {TranslocoModule} from "@ngneat/transloco";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";


@NgModule({
  declarations: [BhGetPremiumComponent],
  exports: [BhGetPremiumComponent],
    imports: [
        CommonModule,
        //NgxStripeModule.forRoot('pk_test_XU616A5o5U02hr71TRDAfJgx'),
        NgxStripeModule.forRoot('pk_live_4R0JoUhfmMQU5BhzyWm30pPo'),
        TranslocoModule,
        BhTooltipModule,
        NgOptimizedImage,
    ]
})
export class BhGetPremiumModule { }
